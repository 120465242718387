export default {
  computed: {
    list() {
      return this.$store.state.moduleMo365.list;
    },
    isLoading() {
      return this.$store.state.moduleMo365.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleMo365/fetchList');
    },
  },
};
