var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-card',{staticClass:"tariff-card",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('router-link',{staticClass:"tariff-card__title-high big-title",attrs:{"to":{ name: 'mo365Detail', params: { id: _vm.tariff.id } }}},[_vm._v(" "+_vm._s(_vm.tariff.title)+" ")])]},proxy:true},{key:"headerAction",fn:function(){return [_c('v-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closePopoverOnOutside),expression:"closePopoverOnOutside"}],attrs:{"placement":"bottom-end","popper-options":_vm.popperOptions,"open":_vm.isPopoverOpen,"trigger":"manual","popover-class":"tariff-card__popover"}},[_c('plain-button',{staticClass:"tariff-card__more",attrs:{"icon":"more"},on:{"click":_vm.togglePopoverState}}),_c('mo-context-menu',{staticClass:"tariff-card__context-menu",attrs:{"slot":"popover","tariff":_vm.tariff},slot:"popover"})],1)]},proxy:true},{key:"headerEnd",fn:function(){return [_c('main-label',{staticClass:"tariff-card__label",attrs:{"color":_vm.tariff.status.color}},[_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.tariff.status.key}`))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('tariff-spec',_vm._b({},'tariff-spec',_vm.tariff.specs,false))]},proxy:true}])},[_c('div',{staticClass:"tariff-card__content"},[_c('div',{staticClass:"tariff-card__title medium-title"},[_vm._v(" "+_vm._s(`${_vm.tariff.pricelist} (${_vm.$tc('period.month_c', _vm.tariff.period)})`)+" ")]),_c('div',{staticClass:"tariff-card__activity"},[(_vm.tariff.promise !== 'null')?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.promiseOn,
          autoHide: false,
          placement: 'top-start',
          container: false,
        }),expression:"{\n          content: promiseOn,\n          autoHide: false,\n          placement: 'top-start',\n          container: false,\n        }"}],staticClass:"allowed-user-rights__promise",attrs:{"icon":"promise-icon"}}):_vm._e(),_c('div',{staticClass:"tariff-card__note note-text"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.isPast ? 'wasActiveUnder' : 'isActiveUnder'}`, { date: _vm.$d(_vm.tariff.expiredate, 'short'), }))+" ")]),_c('div',{staticClass:"tariff-card__activity-actions"},[_c('virtual-autoprolong',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.isAutoProlong,
            autoHide: false,
            placement: 'top-end',
          }),expression:"{\n            content: isAutoProlong,\n            autoHide: false,\n            placement: 'top-end',\n          }"}],staticClass:"tariff-card__autoprolong",attrs:{"tariff":_vm.tariff}}),(_vm.hasProlongTool)?_c('plain-button',{staticClass:"tariff-card__prolong",attrs:{"color":"primary"},on:{"click":function($event){_vm.costAutoProlong === '0.00' ? _vm.singleProlongFree(_vm.tariff) : _vm.singleProlong(_vm.tariff)}}},[_vm._v(" "+_vm._s(_vm.$t('prolong.action'))+" ")]):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }