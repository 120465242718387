<template>
  <div class="mo-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="mo-context-menu__group">
      <component
        :is="item.attrs ? 'a' : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="mo-context-menu__item standart-title"
        :class="{ [`mo-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { Mo365Tariff } from '@/models/BillMgr/Mo365Tariff';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'MoContextMenu',
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof Mo365Tariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tools() {
      return this.$store.state.moduleMo365.tools;
    },
    menu() {
      return [
        [
          {
            key: 'serverPanel',
            disabled: this.tariff.status.code === 1,
            to: 'https://portal.office.com',
          },
        ],
      ];
    },
  },
  methods: {
    onClick(item) {
      window.open(item.to);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "serverPanel":"портал"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.mo-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.mo-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
